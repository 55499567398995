import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectIsIntro } from "../nav/navSlice";

import "./Footer.css"

export default function Footer() {
  const isIntro = useSelector(selectIsIntro);

  return  isIntro ? (
    <footer>
      <h2>공간두드림 주식회사</h2>
      <div>
        <p>서울특별시 송파구 올림픽로 293-19(신천동 7-28), 현대타워 903호 공간두드림 주식회사</p>
        <div>
          <p><a href="tel:02-6956-9863"><b>T</b> 02 6956 9863</a></p>
          <p><b>F</b> 070 8677 9863</p>
        </div>
      </div>
      <p>Hyundai Tower 903, 293-19, Olympic-ro(7-28, Sincheoin-dong), Songpa-gu, Seoul, Republic of Korea</p>
    </footer>
  ) : (<></>)
}




