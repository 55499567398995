import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { _expertise } from "./expertiseAPI"
import { updateIntro, updateFontColor } from "../../nav/navSlice";
import { _navs } from "../../nav/navAPI";
import "./Expertise.css"

export default function Expertise() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(updateFontColor(2));
    dispatch(updateIntro(_navs.filter( item => item.to === window.location.pathname)[0].Id));
  }, [dispatch]);

  let directionFlag = false;

  const listExpertise = _expertise.map( item => {
    directionFlag = !directionFlag;
    return  <div 
              key={`expertise-${item["Id"]}`} 
              className="expertise-item"
              style={directionFlag ? {flexDirection: "row"} :{flexDirection: "row-reverse"} }>
              <div>
                <h3><img src="assets/images/expertise/iconSquare.png" />&nbsp; &nbsp;{item["title"]}</h3>
                <p>{item["description"]}</p>
                <ul>
                  {item["list"].map(item => {
                  return (
                    <li>{item}</li>
                  )})}
                </ul>
              </div>
              <div>
                <img src={item["src"]} />
              </div>
            </div>
  });

  return (
    <main className="expertise-main">
      <h2>사업분야</h2>
      <section className="expertise-section">
        <div className="expertise-item-container">
          {listExpertise ? listExpertise : ""}
        </div>
      </section>
    </main>
  );
}