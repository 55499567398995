import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchMolitPnUofLandQnAContents } from './molitPnUofLandQnAContentsAPI';

import { selectSearchTerm } from '../searchTerm/searchTermSlice';

const initialState = {
  loading: false,
  molitPnUofLandQnAContents: [],
  error: null
};

export const molitPnUofLandQnAContentsLoadDataAsync = createAsyncThunk(
  'molitPnUofLandQnAContents/loadData',
  async ({code, url}) => {
    const response = await fetchMolitPnUofLandQnAContents(url);
    // The value we return becomes the `fulfilled` action payload
    return response.map(item => ({...item, code: code, visible: true}));//{[code]: [...response]};
  }
);

export const loadMolitPnUofLandQnAContents = () => {
  return async (dispatch) => {
    const molitPnUofLandQnAContents = await fetchMolitPnUofLandQnAContents()
    dispatch({type: 'molitPnUofLandQnAContents/addMolitPnUofLandQnAContents', payload: molitPnUofLandQnAContents})
  }
};

export const molitPnUofLandQnAContentsSlice = createSlice({
  name: 'molitPnUofLandQnAContents',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addMolitPnUofLandQnAContents(state, action) {
      state.molitPnUofLandQnAContents = action.payload
    }
    ,updateMolitPnUofLandQnAContents(state, action) {
      const setState = [];
      let temp = [...state.molitPnUofLandQnAContents];
      temp.map(item =>  
        setState.push({...item,
                      visible: action.payload === "00000" ? true : (item.code === action.payload ? true : false)})
      );

      return {
        ...state
        ,molitPnUofLandQnAContents: [...setState] 
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(molitPnUofLandQnAContentsLoadDataAsync.pending, (state) => ({
        ...state,
        loading: true
      }))
      .addCase(molitPnUofLandQnAContentsLoadDataAsync.fulfilled, (state, action) => {
        let temp = [...state.molitPnUofLandQnAContents, ...action.payload];
        const map = new Map();
        for(const item of temp){
          map.set(JSON.stringify(item), item);
        }

        const molitPnUofLandQnAContents = [...map.values()];

        return {
          ...state
          ,molitPnUofLandQnAContents: [...molitPnUofLandQnAContents] 
          ,loading: true
        };
      })
      .addCase(molitPnUofLandQnAContentsLoadDataAsync.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
      }))
  }
});

export const { addMolitPnUofLandQnAContents, updateMolitPnUofLandQnAContents } = molitPnUofLandQnAContentsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectMolitPnUofLandQnAContents = (state) => state.molitPnUofLandQnAContents.molitPnUofLandQnAContents;

export const selectFilteredMolitPnUofLandQnAContents = (state) => {
  const molitPnUofLandQnAContents = selectMolitPnUofLandQnAContents(state);
  const searchTerm = selectSearchTerm(state);

  return molitPnUofLandQnAContents.filter(item => item.title["_text"].toLowerCase().includes(searchTerm.toLowerCase()));
};

export const selectFilteredMolitPnUofLandQnAContents2 = (state, action) => {
  const molitPnUofLandQnAContents = selectMolitPnUofLandQnAContents(state);
  const code = action.payload;

  return molitPnUofLandQnAContents.filter(item => item.code.toLowerCase().includes(code.toLowerCase()));
};

export default molitPnUofLandQnAContentsSlice.reducer;