import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { _introImages } from "./introAPI";
import { updateIntro, updateFontColor } from "../../nav/navSlice";
import { _navs } from "../../nav/navAPI";
import "./Intro.css";

export default function Intro() {
  
  const [ delay, setDelay] = useState(3000);
  const [ currImg, setCurrImg ] = useState(0);
  const [ fadeTarget, setFadeTarget ] = useState("fadeContainer");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const savedCallback = useRef();

  const callback = () => {
    // document.getElementById(fadeTarget).classList.remove('fade-in');
    // document.getElementById(fadeTarget).classList.add('fade-out');
    setTimeout(() => {
      // document.getElementById(fadeTarget).classList.remove('fade-out');
      // document.getElementById(fadeTarget).classList.add('fade-in');
      const randomIndex = Math.floor(Math.random() * _introImages.length);
      dispatch(updateFontColor(randomIndex));
      setCurrImg(randomIndex);
    }, 500);
  }

  useEffect(() => {
    navigate("/home");
    dispatch(updateIntro(undefined));
    savedCallback.current = callback;
  }, [dispatch]);

  useEffect(() => {
    const tick = () => {
        savedCallback.current();
    }

    if (delay !== 0) { // 만약 delay가 null이 아니라면 
      let id = setInterval(tick, delay); // delay에 맞추어 interval을 새로 실행시킨다.
      return () => clearInterval(id); // unmount될 때 clearInterval을 해준다.
    }
  }, [delay]);

  return (
    <main className="intro-main">
      <section className="introImage-section">
        <div id="fadeContainer"
          className="introImage-div bg type-b"
          style={{backgroundImage: `url(${_introImages[currImg].src})`}}
          onMouseEnter={() => setDelay(0)} 
          onMouseLeave={() => setDelay(3000)} 
          onClick={() => navigate("/home")} >
            <div className="center">
              <h2>{_introImages[currImg].title}</h2>
              <p>{_introImages[currImg].description}</p>
            </div>
        </div>
      </section>
    </main>
  );
}