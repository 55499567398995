import React, { useState } from 'react';
import { Link } from "react-router-dom";

import { 
  Drawer
  ,IconButton
  ,List
  ,ListItemButton
  ,ListItemIcon
  ,ListItemText
  ,Box
  ,Divider  
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu'

export default function Hamburger(props) {
  const [openDrawer, setOpenDrawer] = useState(false);

  const listItem = props.navs && props.navs.map(item => {
    return (
      <ListItemButton 
        key={item["Id"]} 
        to={item["to"]} 
        component={Link}>
        <ListItemIcon>
          <ListItemText 
            style={{color: 'gray'}}>
            {item["displayName"]} 
          </ListItemText>
        </ListItemIcon>
      </ListItemButton>
    );
  });

  return (
    <React.Fragment>
      <Drawer 
        anchor='top'
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}>
        <Box
          sx={{ width:'auto'}}
          role="presentation"
          onClick={() => setOpenDrawer(!openDrawer)}
          onKeyDown={() => setOpenDrawer(!openDrawer)}
        >
          <List>
                {listItem ? listItem : ""}
          </List>
          <Divider />
        </Box>
      </Drawer>
      <IconButton 
        sx={{color:  'gray', marginLeft: 'auto'}}
        onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
    </React.Fragment>
  );

}