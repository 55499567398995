import { _userAuthorityCode } from "../../../util/codeSet";

export const _contactUsImgs = [
  {
    Id: "01"
    ,title: "도시계획 엔지니어링"
    ,description: "도시계획·도시설계·도시정비"
    ,src: "assets/images/contactUs/undraw_city_life_gnpr1.png"
    ,link: "02-6956-9863"
  }
  ,{
    Id: "02"
    ,title: "부동산개발 컨설팅"
    ,description: "사업타당성분석"
    ,src: "assets/images/contactUs/undraw_Data_re_80ws1.png"
    ,link: "02-6956-9863"
  }
  ,{
    Id: "03"
    ,title: "IT 개발"
    ,description: "웹/모바일기획·개발·운영 "
    ,src: "assets/images/contactUs/undraw_Pair_programming_re_or4x1.png"
    ,link: "02-6956-9863"
  }
]