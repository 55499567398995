export const _homeImages = [
  {
    Id: "01",
    title: "공간두드림은",
    description: "창조적인 생각과 합리적인 조율을 통해, 보다 나은 미래 가치를 창조하는 도시계획 전문회사입니다.",
    src: "assets/images/home/Image_Slide-1.png"
  }
  ,{
    Id: "02",
    title: "Consult Your Business",
    description: "We are the unique Consultancy for your business solution, That is ready to take challenge and knockout your business problems.",
    src: "assets/images/home/Image_Slide-2.png"
  }
  ,{
    Id: "03",
    title: "공간두드림은",
    description: "창조적인 생각과 합리적인 조율을 통해, 보다 나은 미래 가치를 창조하는 도시계획 전문회사입니다.",
    src: "assets/images/home/Image_Slide-3.png"
  }
  ,{
    Id: "03",
    title: "Consult Your Business",
    description: "We are the unique Consultancy for your business solution, That is ready to take challenge and knockout your business problems.",
    src: "assets/images/home/Image_Slide-4.png"
  }
]
