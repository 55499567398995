import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { molitPnUofLandQnACodesLoadDataAsync, selectMolitPnUofLandQnACodes } from './molitPnUofLandQnACodesSlice';

import { molitPnUofLandQnAContentsLoadDataAsync } from '../molitPnUofLandQnAContents/molitPnUofLandQnAContentsSlice';

import { updateMolitPnUofLandQnAContents } from '../molitPnUofLandQnAContents/molitPnUofLandQnAContentsSlice';

import { Button } from '@mui/material';
import "./MolitPnUofLandQnACodes.css"

export default function MolitPnUofLandQnACodes () {
  const molitPnUofLandQnACodes = useSelector(selectMolitPnUofLandQnACodes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(molitPnUofLandQnACodesLoadDataAsync());
  }, [dispatch])

  const onAddRecipeHandler = (code) => {
      dispatch(updateMolitPnUofLandQnAContents(code));
  };

  const listItem = molitPnUofLandQnACodes && molitPnUofLandQnACodes.map( code => {
    dispatch(molitPnUofLandQnAContentsLoadDataAsync({code: code.catecd._text, url: code.answerlistlink._cdata})); 
    return (
      <Button key={code.catecd._text}
        style={{color: "#FFFFFF"}}
        onClick={() => onAddRecipeHandler(code.catecd._text)}
        className="btn-gradient blue" >
        {code.catename._text}
      </Button>
    )
  })

  return (
    <React.Fragment>
    <Button 
      key="00000"
      style={{color: "#FFFFFF"}}
      onClick={() => onAddRecipeHandler("00000")} 
      className="btn-gradient blue">
      전체
    </Button>
    { listItem ? listItem : "" }
    </React.Fragment>
  );
};

