import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isIntro: undefined,
  fontColor: 0
};

export const navSlice = createSlice({
  name: 'nav',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateIntro: (state, action) => {
      state.isIntro = action.payload;
    },
    updateFontColor: (state, action) => {
      state.fontColor = action.payload;
    },
  }
});


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectIsIntro = (state) => state.nav.isIntro;
export const selectFontColor = (state) => state.nav.fontColor;
export const { updateIntro,  updateFontColor} = navSlice.actions;
export default navSlice.reducer;