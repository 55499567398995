export const _expertise = [
  {
    Id: "01",
    title: "도시계획",
    description: "도시의 장·단기적인 계획을 통해 국토의 계획 및 효율적 이용을 도모하고, 인간의 주거와 활동을 능률적이고 효과적으로 공간에 배치하여 도시민의 삶의 질을 보장하기 위해 지속가능한 발전 도모",
    list: [],
    src: "assets/images/expertise/urbanPlanning.png"
  }
  ,{
    Id: "02",
    title: "지구단위계획",
    description: "도시·군계획 수립 대상지역의 일부에 대하여 토지 이용을 합리화하고 그 기능을 증진시키며 미관을 개선하고 양호한 환경을 확보하며, 그 지역을 체계적·계획적으로 관리하기 위한 도시·군관리계획에 대한 세부적인 계획수립",
    list: ["지구단위계획 수립(지정 및 결정)"],
    src: "assets/images/expertise/districtUnitPlan.png"
  }
  ,{
    Id: "03",
    title: "도시정비",
    description: "주거환경이 불량한 지역을 계획적으로 정비하고 노후ㆍ불량건축물을 효율적으로 개량하여 도시환경을 개선하고 주거생활의 질을 향상",
    list: ["주택재건축.재개발 정비사업", "주거환경개선사업", "도시환경정비사업"],
    src: "assets/images/expertise/urbandevelopment.png"
  }
  ,{
    Id: "04",
    title: "부동산개발 컨설팅",
    description: "토지 매입에 관한 타당성 검토, 개발계획, 인허가프로세스 컨설팅 등 전반적인 부동산 개발사업의 컨설턴트",
    list: [],
    src: "assets/images/expertise/realEstateDevelopmentConsulting.png"
  }
  ,{
    Id: "05",
    title: "IT개발",
    description: "최적화된 사용자 경험과 최신 트렌드 및 다양한 디바이스 환경을 고려한 기업 홈페이지ㆍ모바일 사이트 구축 및 운영을 통해 기업의 온라인 홍보 활동에 기여",
    list: ["반응형 기업 홈페이지 구축", "모바일 어플리케이션 개발", "사이트 관리 및 유지보수"],
    src: "assets/images/expertise/ITDevelopment.png"
  }
]


export const _expertisefields = [
  {
    Id: "01"
    ,title: "도시계획 엔지니어링"
    ,description: "도시계획·도시설계·도시정비"
    ,src: "assets/images/contactUs/undraw_city_life_gnpr1.png"
    ,link: "02-6956-9863"
  }
  ,{
    Id: "02"
    ,title: "부동산개발 컨설팅"
    ,description: "사업타당성분석"
    ,src: "assets/images/contactUs/undraw_Data_re_80ws1.png"
    ,link: "02-6956-9863"
  }
  ,{
    Id: "03"
    ,title: "IT 개발"
    ,description: "웹/모바일기획·개발·운영 "
    ,src: "assets/images/contactUs/undraw_Pair_programming_re_or4x1.png"
    ,link: "02-6956-9863"
  }
]