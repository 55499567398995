import React, { useEffect} from 'react';
import { useDispatch } from "react-redux";

import NaverMap from "../../../components/NaverMap";
import { updateIntro, updateFontColor } from "../../nav/navSlice";
import { _contactUsImgs } from "./contactUsAPI";
import { _navs } from "../../nav/navAPI";
import "./ContactUs.css";

export default function ContactUs() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateFontColor(2));
    dispatch(updateIntro(_navs.filter( item => item.to === window.location.pathname)[0].Id));
  }, [dispatch]);

  const listContactUs = _contactUsImgs.map( item => {
    return  <div 
              key={`contactUs-${item["Id"]}`} 
              className="contactUs-item">
              <img src={item["src"]} alt=""/>
              <h3>{item["title"]}</h3>
              <a href={`tel:${item["link"]}`}>문의하기 {'>'}</a>
            </div>
  });

  return (
    <main className='contactUs-main'>
      <h2>문의하기</h2>
      <img src="assets/images/contactUs/topImage.png" alt=""/>
      <div>
        <p>공간두드림 홈페이지를 찾아주셔서 감사합니다.</p>
        <p>고객님의 의견을 문의해주시면 성실히 응답해 드리겠습니다</p>
      </div>
      <section className='contactUs-section'>
      
        {listContactUs ? listContactUs : ""}
      </section>
      <section className='map-section'>
        <h2>오시는 길</h2>
        <div>
          <p><img src="assets/images/contactUs/addrIcon.png" alt=""/>&nbsp; <b>주&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;소</b>&nbsp;&nbsp;&nbsp;서울특별시 송파구 올림픽로 293-19(신천동 7-28), 현대타워 903호&nbsp;&nbsp;<b>공간두드림 주식회사</b></p>
          <p><img src="assets/images/contactUs/transIcon.png" alt=""/>&nbsp;<b>대중교통</b>&nbsp;&nbsp;&nbsp;잠실역(2호선)하차 8번출구 / 잠실역(8호선)하차 9번출구</p>
        </div>
        <NaverMap />
      </section>
    </main>
  );
} 