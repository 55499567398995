import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from '../features/session/sessionSlice';
import searchTermReducer from '../features/main/sections/searchTerm/searchTermSlice';
import molitPnUofLandQnACodesReducer from '../features/main/sections/molitPnUofLandQnACodes/molitPnUofLandQnACodesSlice';
import molitPnUofLandQnAContentsReducer from '../features/main/sections/molitPnUofLandQnAContents/molitPnUofLandQnAContentsSlice';
import naverNewsReducer from '../features/main/sections/naverNews/naverNewsSlice';
import navReducer from '../features/nav/navSlice';


export const store = configureStore({
  reducer: {
    session: sessionReducer
    ,searchTerm: searchTermReducer
    ,molitPnUofLandQnACodes: molitPnUofLandQnACodesReducer
    ,molitPnUofLandQnAContents: molitPnUofLandQnAContentsReducer
    ,naverNews: naverNewsReducer
    ,nav: navReducer
  }
});