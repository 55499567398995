import { createSlice } from '@reduxjs/toolkit';
import { _userAuthorityCode } from '../../util/codeSet';

const initialState = {
  user: {
         username: "",
         authority: _userAuthorityCode.state1 + _userAuthorityCode.state2
        },
  isLoggedIn: false
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    signUp: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    editUser: (state, action) => {
      state.user = action.payload;
    },
    logOut: (state, action) => {
      state.user = {authority: _userAuthorityCode.state1 + _userAuthorityCode.state2};
      state.isLoggedIn = false;
    }
  }
});

export const selectCurrentUser = (state) => state.session.user;
export const selectIsLoggedIn = (state) => state.session.isLoggedIn;
export const { signUp, logOut, editUser } = sessionSlice.actions;
export default sessionSlice.reducer;