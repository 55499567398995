import React, { useState, useEffect }  from "react";
import { useSelector} from "react-redux";

import { selectFilteredMolitPnUofLandQnAContents } from "./molitPnUofLandQnAContentsSlice";

import "./MolitPnUofLandQnAContents.css"

export default function MolitPnUofLandQnAContents() {
  const molitPnUofLandQnAContents = useSelector(selectFilteredMolitPnUofLandQnAContents)
  const [ data, setData] = useState(molitPnUofLandQnAContents);

  useEffect(() => {
    // dispatch(allRecipesLoadDataAsync());
    setData(molitPnUofLandQnAContents);;
  }, [molitPnUofLandQnAContents])

  const listItem = data && Object.values(data).filter(item => item.visible).map( item => {
      return ( 
        <article 
          key={`molitPnU-${item["answno"]["_text"]}`}
          className="molitPnUofLandQnAContent-item">
          <h3><a href={item["answerviewlink"]["_text"]} target='_blank' rel="noreferrer">{item["title"]["_text"]}</a></h3>
          <p>{item["caseno"]["_text"]}</p>
        </article>
      )

    });

  return (
    <React.Fragment >
      {listItem ? listItem : ""}
    </React.Fragment>
  );
}