import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchMolitPnUofLandQnACodes } from './molitPnUofLandQnACodesAPI';

const initialState = {
  loading: false,
  molitPnUofLandQnACodes: [],
  error: null
};

export const molitPnUofLandQnACodesLoadDataAsync = createAsyncThunk(
  'molitPnUofLandQnACodes/loadData',
  async () => {
    const response = await fetchMolitPnUofLandQnACodes();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);


export const molitPnUofLandQnACodesSlice = createSlice({
  name: 'molitPnUofLandQnACodes',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(molitPnUofLandQnACodesLoadDataAsync.pending, (state) => ({
        ...state,
        loading: true
      }))
      .addCase(molitPnUofLandQnACodesLoadDataAsync.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        molitPnUofLandQnACodes: [ ...action.payload]
      }))
      .addCase(molitPnUofLandQnACodesLoadDataAsync.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
      }))
  }
});


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectMolitPnUofLandQnACodes = (state) => state.molitPnUofLandQnACodes.molitPnUofLandQnACodes;

export default molitPnUofLandQnACodesSlice.reducer;