
import { _userAuthorityCode } from "../../util/codeSet"

import ContactUs from "./contactUs/ContactUs";
import SignUp from "./signUp/SignUp";
import News from "./news/News";
import MolitPnUofLandQnA from "./molitPnUofLandQnA/MolitPnUofLandQnA";
import Intro from "./intro/Intro";
import Work from "./work/Work";
import Service from "./service/Service";
import Home from "./home/Home"
import Expertise from "./expertise/Expertise";
import Test from "./test/Test";


// import Articles from "../features/articles/articles";
// import Categories from "../features/categories/Categories";
// import SignUp from "./SignUp";
// import Profile from "./Profile";

// import Article from "../features/articles/Article";
// import Author from "../features/authors/Author";

export const _routes = [
  {
    path: "/",
    component: <Intro />,
    authority: _userAuthorityCode.state1
  }
  ,{
    path: "/home",
    component: <Home />,
    authority: _userAuthorityCode.state1
  }
  ,{
    path: "/works",
    component: <Work />,
    authority: _userAuthorityCode.state1
  }
  ,{
    path: "/expertise",
    component: <Expertise />,
    authority: _userAuthorityCode.state1
  }
  ,{
    path: "/service",
    component: <Service />,
    authority: _userAuthorityCode.state1
  }
  ,{
    path: "/contactUs",
    component: <ContactUs />,
    authority: _userAuthorityCode.state1
  }
  ,{
    path: "/news",
    component: <News />,
    authority: _userAuthorityCode.state1
  }
  ,{
    path: "/molitPnUofLandQnA",
    component: <MolitPnUofLandQnA />,
    authority: _userAuthorityCode.state1
  }
  // ,{
  //   path: "/test",
  //   component: <Test />,
  //   authority: _userAuthorityCode.state1
  // }
  // {
  //   path: "/articles/:title",
  //   component: <Article />,
  //   authority: _userAuthorityCode.state1
  // }
  // ,{
  //   path: "/articles",
  //   component: <Articles />,
  //   authority: _userAuthorityCode.state1
  // }
  // ,{
  //   path: "/authors/:name",
  //   component: <Author />,
  //   authority: _userAuthorityCode.state1
  // }
  // ,{
  //   path: "/profile/*",
  //   component: <Profile />,
  //   authority: _userAuthorityCode.state4
  // }
  ,{
    path: "/signUp",
    component: <SignUp />,
    authority: _userAuthorityCode.state2
  }
]