export const fetchNaverNews = async () => {
  const url = `/fetchNaverNews`;

  try{
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    });

    if(response.ok) {
      const jsonResponse = await response.json();
      return jsonResponse;
    }

  } catch(error) {
    console.log(error);
  }
}