import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { signUp } from "../../session/sessionSlice";
import { _userAuthorityCode } from "../../../util/codeSet";

export default function SignUp () {
  const [user, setUser] = useState({username : "", authority: _userAuthorityCode.state1 + _userAuthorityCode.state2});
  const dispatch = useDispatch();
  
  // Grab the history object
  const navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(signUp(user));
    // imperatively redirect the user to /profile
    navigate(`/profile`);
  }

  const handleChange = ({ target }) => {
    const { value } = target;
    setUser((prev) => ({
      ...prev,
      username: value,
      authority: _userAuthorityCode.state1 + _userAuthorityCode.state4
    }));
  };

  return (
    <main>
      <section>
        <h1>Sign Up</h1>
        <form onSubmit={handleSubmit}>
          <label>
            Username
            <div>
              <input
                id="username"
                value={user.username}
                onChange={handleChange}
              />
              <button type="submit" className="primary">
                Sign Up
              </button>
            </div>
          </label>
        </form>
      </section>
    </main>
  );
}