import React, {useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { selectCurrentUser, logOut} from "../session/sessionSlice";
import { selectIsIntro, selectFontColor } from "./navSlice";
import { _userAuthorityCode, _siteUrl } from '../../util/codeSet';
import { capitalizeByIndex } from "../../util/stringUtil";
import { _navs, _navFontColor } from "./navAPI"
import Hamburger from "./Hamburger";

import "./Nav.css"


import { AppBar 
  ,Typography
  ,Toolbar
  ,Tabs
  ,Tab
  ,Button 
  ,useMediaQuery
  ,useTheme
  } from "@mui/material";



export default function Nav() {
  const isIntro = useSelector(selectIsIntro);
  let menuId = undefined;
  if(window.location.pathname !== "/") {
    menuId = Number(_navs.filter( item => item.to === window.location.pathname)[0].Id);
  }
  const [value, setValue] = useState(menuId);
  const currentUser = useSelector(selectCurrentUser);
  const fontColor = useSelector(selectFontColor);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const navs = _navs.filter(nav => nav["authority"] & ( nav["authority"] & currentUser.authority))
                    .map(nav => {return { ...nav, displayName: capitalizeByIndex(nav["displayName"], " ", 0, 1)}})
                   
  const handleLogout = () => {
    dispatch(logOut())
  }

  const listItem = navs && navs.map(item => {
    return (
        <Tab 
          key={item["Id"]} 
          label={<span className="label-tab-nav">{item["displayName"]}</span>}
          //label={item["displayName"]}

          to={item["to"]} 
          component={Link} />
    );
  });

  return (
    <React.Fragment>
      <AppBar 
        position="fixed" 
        color= {isIntro ? "inherit" : "transparent"}
        sx={isIntro ? { borderBottom: '0.25rem solid rgb(0, 47, 189)'} : {}}
        elevation={0}>
        <Toolbar sx={{ alignItems: 'flex-start'}}>
          {isIntro && (
            <a href={_siteUrl}>
            <img 
              src="assets/images/logo/aousCI.png" 
              className="log-image"
              alt="logo" />
            </a>
              //onClick={() => navigate("/")} />
)}
{
  isMatch ? (
        <>
          <Hamburger navs={navs} />
        </>
  ) : (
        <>
          <Tabs sx={{marginLeft: 'auto'}}
            TabIndicatorProps={{style: {background:'rgb(0, 47, 189)'}}}
            value={value} 
            onChange={(e, value) => setValue(value)}
            textColor="primary">

            {listItem ? listItem : ""}
          </Tabs>
        
          
          {/* {_userAuthorityCode.state4 & ( _userAuthorityCode.state4 & currentUser.authority) ? <Button sx={{marginLeft: '10px'}} variant="contained" onClick={handleLogout} > Log Out </Button> : <><Button sx={{marginLeft: 'auto'}} variant="contained" onClick={handleLogout} > Log In </Button> <Button sx={{marginLeft: '10px'}}  variant="contained" onClick={handleLogout} > Sing Up</Button></>} */}
        </>
  )
}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
