import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateIntro, updateFontColor } from "../../nav/navSlice";

import { _navs } from "../../nav/navAPI";
import "./Work.css";

export default function Work() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateFontColor(2));
    dispatch(updateIntro(_navs.filter( item => item.to === window.location.pathname)[0].Id));
  }, [dispatch]);

  

  return (
    <main className="work-main">
      <section className="work-section">
        <h2>사업실적</h2>
        <div>
          <img 
            src="assets/images/works/works_page.png"
            className="work-img" 
            alt="works"/>
        </div>
      </section>
    </main>
  );
}