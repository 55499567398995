export const fetchMolitPnUofLandQnACodes = async () => {
  const url = `/fetchMolitPnUofLandQnACodes`;

  try{
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    });

    if(response.ok) {
      
      const jsonResponse = await response.json();
      return jsonResponse.root.AnswerList;
    }

  } catch(error) {
    console.log(error);
  }

}


