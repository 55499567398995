import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { _homeImages } from "./homeAPI.js";
import { updateIntro, updateFontColor } from "../../nav/navSlice";
import { _navs } from "../../nav/navAPI";
import "./Home.css";

export default function Home() {
  const [ delay, setDelay] = useState(5000);
  const [ currImg, setCurrImg ] = useState(0);
  const [ fadeTarget, setFadeTarget ] = useState("fadeContainer");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  

  const savedCallback = useRef();

  const callback = () => {
    document.getElementById(fadeTarget).classList.remove('fade-in');
    document.getElementById(fadeTarget).classList.add('fade-out');
    setTimeout(() => {
      document.getElementById(fadeTarget).classList.remove('fade-out');
      document.getElementById(fadeTarget).classList.add('fade-in');
      setCurrImg(Math.floor(Math.random() * _homeImages.length));
    }, 500);
  }

  useEffect(() => {
    dispatch(updateFontColor(2));
    dispatch(updateIntro(_navs.filter( item => item.to === window.location.pathname)[0].Id));
    
    savedCallback.current = callback;
  }, [dispatch]);

  useEffect(() => {
    const tick = () => {
        savedCallback.current();
    }

    if (delay !== 0) { // 만약 delay가 null이 아니라면 
      let id = setInterval(tick, delay); // delay에 맞추어 interval을 새로 실행시킨다.
      return () => clearInterval(id); // unmount될 때 clearInterval을 해준다.
    }
  }, [delay]);

  const handleArrowBack = (e) => {
    document.getElementById(fadeTarget).classList.remove('fade-in');
    document.getElementById(fadeTarget).classList.add('fade-out');
    setTimeout(() => {
      document.getElementById(fadeTarget).classList.remove('fade-out');
      document.getElementById(fadeTarget).classList.add('fade-in');
      currImg > 0 && setCurrImg(currImg - 1);
    }, 500);
  }

  const handleArrowForward = (e) => {
    document.getElementById(fadeTarget).classList.remove('fade-in');
    document.getElementById(fadeTarget).classList.add('fade-out');
    setTimeout(() => {
      document.getElementById(fadeTarget).classList.remove('fade-out');
      document.getElementById(fadeTarget).classList.add('fade-in');
      currImg < (_homeImages.length - 1) && setCurrImg(currImg + 1);
    }, 500);
  }

  const listServicefields = _homeImages.map( item => {
    return  <div 
              key={`servicefield-${item["Id"]}`} 
              className="servicefield-item">
              <img src={item["src"]} />
              <h3>{item["title"]}</h3>
              <p>{item["description"]}</p>
              
              <a href={`tel:${item["link"]}`}>문의하기 {'>'}</a>
            </div>
  });

  return (
    <main className="home-main">
      <section className="homeImage-section">
        <div id="fadeContainer"
          className="homeImage-div fade-in"
          style={{backgroundImage: `url(${_homeImages[currImg].src})`}}
          onMouseEnter={() => setDelay(0)} 
          onMouseLeave={() => setDelay(5000)} >
            <div 
              className="left"
              onClick={handleArrowBack}>
              <ArrowBackIosIcon style={{fontSize: "3rem"}}/>
            </div>
            <div className="center">
              <h2>{_homeImages[currImg].title}</h2>
              <p>{_homeImages[currImg].description}</p>
            </div>
            <div className="right" 
              onClick={handleArrowForward}>
              <ArrowForwardIosIcon style={{fontSize: "3rem"}}/>
            </div>
        </div>
      </section>
      <section className="story-section">
        <h2>STORY</h2>
        <div>
          <a onClick={() => navigate("/works")}><img src="assets/images/home/WORK.png" alt="work" /></a>
          <a onClick={() => navigate("/expertise")}><img src="assets/images/home/EXPERTISE.png" alt="work" /></a>
          <a onClick={() => navigate("/service")}><img src="assets/images/home/SERVICE.png" alt="work" /></a>
          <a onClick={() => navigate("/contactUs")}><img src="assets/images/home/CONTACTUS.png" alt="work" /></a>
          <a onClick={() => navigate("/news")}><img src="assets/images/home/NEWS.png" alt="work" /></a>
        </div>
      </section>
    </main>
  );  
}