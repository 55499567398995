import React from 'react';
import { useSelector, useDispatch } from "react-redux";

import { setSearchTerm, clearSearchTerm, selectSearchTerm } from './searchTermSlice';

import "./searchTerm.css"

const searchIconUrl = 'https://static-assets.codecademy.com/Courses/Learn-Redux/Recipes-App/icons/search.svg'
const clearIconUrl = 'https://static-assets.codecademy.com/Courses/Learn-Redux/Recipes-App/icons/clear.svg'


export default function SearchTerm () {
  const searchTerm = useSelector(selectSearchTerm);
  const dispatch = useDispatch();

  const onSearchTermChangeHandler = ({target}) => {
    const  { value } = target;
    dispatch(setSearchTerm(value));
  };
  
  const onClearSearchTermHandler = () => {
    dispatch(clearSearchTerm());
  };

  return (
    <section id="search-container">
      <div class="search">
        <img id="search-icon" alt="" src={searchIconUrl} />
        <input
          id="search"
          type="text"
          className='box'
          value={searchTerm}
          onChange={onSearchTermChangeHandler}
          placeholder="Search recipes"
        />
        {searchTerm.length > 0 && (
          <button
            onClick={onClearSearchTermHandler}
            type="button"
            id="search-clear-button"
          >
            <img src={clearIconUrl} alt="" />
          </button>
        )}
      </div>
    </section>
  );
};
