export const _userAuthorityCode = {
  state1: 1,
  state2: 2,
  state4: 4
}


//export const _siteUrl = "http://localhost:3000/"
export const _siteUrl = "http://www.aous.co.kr/"

