import React from "react";
import Nav from "../nav/Nav";

export default function Header() {

  return (
    <React.Fragment>
      <Nav/>
    </React.Fragment>
      
  );
}