import React, { useEffect} from "react";
import { useDispatch } from "react-redux";

import SearchTerm from '../sections/searchTerm/SearchTerm';
import NaverNews from '../sections/naverNews/NaverNews';

import { updateIntro, updateFontColor } from "../../nav/navSlice";
import { _navs } from "../../nav/navAPI";
import "./News.css"

export default function News() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateFontColor(2));
    dispatch(updateIntro(_navs.filter( item => item.to === window.location.pathname)[0].Id));
  }, [dispatch]);

  return (
    <main className='news-main'>
      <h2>NEWS</h2>
      <section className="search-section">
        <SearchTerm />
      </section>
      <section className='naverNew-section'>
        
        <div className='naverNew-item-container'>
          <NaverNews />
        </div>
      </section>
    </main>
  )
}