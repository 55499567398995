import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Header from "../features/header/Header";
import Main from "../features/main/Main";
import Footer from "../features/footer/Footer";

import "./App.css"

export default function App() {
  return (
    <Router>
        <Header />
        <Main />
        <Footer />
    </Router>
  );
}
