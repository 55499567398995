import { rgbToHex } from "@mui/material"
import { _userAuthorityCode } from "../../util/codeSet"

export const _navs = [
  {
    Id: "0",
    displayName: "home",
    defaultClassName: "",
    activeClassName: "",
    to: "/home",
    authority: _userAuthorityCode.state1
  }
  ,{
    Id: "1",
    displayName: "works",
    defaultClassName: "",
    activeClassName: "",
    to: "/works",
    authority: _userAuthorityCode.state1
  }
  ,{
    Id: "2",
    displayName: "expertise",
    defaultClassName: "",
    activeClassName: "",
    to: "/expertise",
    authority: _userAuthorityCode.state1
  }
  ,{
    Id: "3",
    displayName: "service",
    defaultClassName: "",
    activeClassName: "",
    to: "/service",
    authority: _userAuthorityCode.state1
  }
  ,{
    Id: "4",
    displayName: "contactUs",
    defaultClassName: "",
    activeClassName: "",
    to: "/contactUs",
    authority: _userAuthorityCode.state1
  }
  ,{
    Id: "5",
    displayName: "news",
    defaultClassName: "",
    activeClassName: "",
    to: "/news",
    authority: _userAuthorityCode.state1
  }
  ,{
    Id: "6",
    displayName: "QnA",
    defaultClassName: "",
    activeClassName: "",
    to: "/molitPnUofLandQnA",
    authority: _userAuthorityCode.state1
  }
  // ,{
  //   Id: "7",
  //   displayName: "TEST",
  //   defaultClassName: "",
  //   activeClassName: "",
  //   to: "/test",
  //   authority: _userAuthorityCode.state1
  // }
]

export const _navFontColor = [
  {
    id: "0",
    color: "rgb(255, 255, 255)"
  }
  ,{
    id: "2",
    color: "rgb(0, 0, 0)"
  }
  ,{
    id: "3",
    color: "rgb(125, 125, 125)"
  }
]