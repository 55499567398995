import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";

import { selectCurrentUser } from "../session/sessionSlice";
import { _routes } from "./mainAPI";


export default function Main() {

  const currentUser = useSelector(selectCurrentUser);
  return (
    <Routes>
      {_routes.filter(route => route["authority"] & ( route["authority"] & currentUser.authority))
              .map(route => {
                return <Route key={route["path"]}
                        path={route["path"]}
                        element={route["component"]} />;
              })}
    </Routes>
  );
}