import React, { useEffect} from 'react';
import { useDispatch } from "react-redux";

import SearchTerm from '../sections/searchTerm/SearchTerm';
import MolitPnUofLandQnACodes from '../sections/molitPnUofLandQnACodes/MolitPnUofLandQnACodes';
import MolitPnUofLandQnAContents from '../sections/molitPnUofLandQnAContents/MolitPnUofLandQnAContents';

import { updateIntro, updateFontColor } from "../../nav/navSlice";
import { _navs } from "../../nav/navAPI";
import "./MolitPnUofLandQnA.css";

export default function MolitPnUofLandQnA() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateFontColor(2));
    dispatch(updateIntro(_navs.filter( item => item.to === window.location.pathname)[0].Id));
  }, [dispatch]);

  return (
    <main className='molit-main'>
      <h2>질의회신</h2>
      <section className='search-section'>
        <SearchTerm />
      </section>
      <section className='molitCode-section'>
        <MolitPnUofLandQnACodes />
      </section>
      <section className='molitPnUofLandQnA-section'>
        <h3>「국토의 계획 및 이용에 관한 법률」질의회신 사례</h3>
        <div className='molitPnUofLandQnA-item-container'>
          <MolitPnUofLandQnAContents />
        </div>
      </section>
    </main>
  )
}