import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchNaverNews } from './naverNewsAPI';

import { selectSearchTerm } from '../searchTerm/searchTermSlice';

const initialState = {
  loading: false,
  naverNews: [],
  error: null
};

export const naverNewsLoadDataAsync = createAsyncThunk(
  'naverNews/loadData',
  async () => {
    const response = await fetchNaverNews();
    // The value we return becomes the `fulfilled` action payload
    return response.items;
  }
);


export const naverNewsSlice = createSlice({
  name: 'naverNews',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(naverNewsLoadDataAsync.pending, (state) => ({
        ...state,
        loading: true
      }))
      .addCase(naverNewsLoadDataAsync.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        naverNews: [ ...action.payload]
      }))
      .addCase(naverNewsLoadDataAsync.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
      }))
  }
});


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectNaverNews = (state) => state.naverNews.naverNews;

export const selectFilteredNaverNews = (state) => {
  const molitPnUofLandQnAContents = selectNaverNews(state);
  const searchTerm = selectSearchTerm(state);

  return molitPnUofLandQnAContents.filter(item => item.title.toLowerCase().includes(searchTerm.toLowerCase()));
};

export default naverNewsSlice.reducer;