import React, { useEffect }  from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectFilteredNaverNews, naverNewsLoadDataAsync } from "./naverNewsSlice";

import "./NaverNews.css"

export default function NaverNews() {
  const naverNews = useSelector(selectFilteredNaverNews);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(naverNewsLoadDataAsync());
  }, [dispatch]);

  const listItem = naverNews && naverNews.map((item, index) => {
    return  <article 
              key={`naverNews-${index}`}
              className="naverNew-item">
              <a href={item["originallink"]} target='_blank' rel="noreferrer"><h3 dangerouslySetInnerHTML={ {__html: `${item["title"]}`} }></h3></a>
              <p dangerouslySetInnerHTML={ {__html: `${item["description"]} ${item["pubDate"]}`} }></p>
            </article>
  });

  return (<> {listItem ? listItem : ""}</>);
}