import { createSlice } from '@reduxjs/toolkit';

const initialState = '';

export const searchTermSlice = createSlice({
  name: 'searchTerm',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSearchTerm: (state, action) => {
      return action.payload;
    },

    clearSearchTerm: (state) => {
      return '';
    }
  }
});


export const { setSearchTerm,  clearSearchTerm } = searchTermSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSearchTerm = (state) => state.searchTerm;

export default searchTermSlice.reducer;