import React from "react";
import { useEffect } from "react";

export default function NaverMap() {

  useEffect(() => {

    if(!document.getElementById("naverMapScript")) {
      const fileScript = document.createElement("script");
      fileScript.id = "naverMapScript";
      fileScript.src = "https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=46zbzc8e4p&submodules=panorama";
      document.body.appendChild(fileScript);
      
      fileScript.onload = () => {

        var mapOptions = {
          mapTypeId: window.naver.maps.MapTypeId.TERRAIN,
          center: new window.naver.maps.LatLng( 37.51514311692641, 127.1024855373187),
          zoom: 16
        }

        var map = new window.naver.maps.Map('map', mapOptions);

        var marker = new window.naver.maps.Marker({
            position: new window.naver.maps.LatLng( 37.51514311692641, 127.1024855373187),
            map: map
        });

        var pano = null;

        window.naver.maps.onJSContentLoaded = function() {

          pano = new window.naver.maps.Panorama("pano", {

            position: new window.naver.maps.LatLng(37.51527833105052, 127.10241219901576),
            pov: {
                pan: 160,
                tilt: 29,
                fov: 100
            },
            flightSpot: true, 
          });
        };
      };
      
      fileScript.onerror = () => {
        console.log("load error!");
      };
    }
  }, []);

  const mapStyle = {
    width: "100%", 
    height: "400px"
  }

  return (
    <React.Fragment>
      <div id="map" style={mapStyle}></div>
    </React.Fragment>
  );
}
