export const _representativeIntroduction = [
  {
    Id: "01",
    title: "1988",
    description: "아주대학교 건축학과 졸업"
  }
  ,{
    Id: "02",
    title: "1988",
    description: "코오롱건설㈜"
  }
  ,{
    Id: "03",
    title: "2004",
    description: "㈜하우드엔지니어링 종합건축사 사무소"
  }
  ,{
    Id: "04",
    title: "2006",
    description: "㈜엠와이앤씨"
  }
  ,{
    Id: "05",
    title: "2013",
    description: "㈜한림건축씨엠종합건축사 사무소"
  }
  ,{
    Id: "06",
    title: "2019",
    description: "공간두드림 주식회사 창립"
  }
]


export const _services = [
  {
    Id: "01",
    title: "관련법규/규제사항검토",
    description: "",
    list: ["관련법규 등 도시계획 제 규제사항 검토", "협업 공조체계", "전문성 강화 "],
    src: "assets/images/service/reviewingRelatedRegulatoryMatters.png"
  }
  ,{
    Id: "01",
    title: "합리적 대안수립",
    description: "",
    list: ["협업을 통한 사업주 이익 극대화 방안 ", "개발에 필요한 논리 마련", "대안을 통한 공공/ 발주처 입장 반영"],
    src: "assets/images/service/establishingReasonableAlternative.png"
  }
  ,{
    Id: "01",
    title: "인허가 리스크 최소화",
    description: "",
    list: ["분야별 상호 협력을 통한 인허가 대응", "사전 대비/대안 마련", "    협력체계를 통해 효율적 대응"],
    src: "assets/images/service/minimizeLicensingRisk.png"
  }
]