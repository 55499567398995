import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { _representativeIntroduction, _services } from "./serviceAPI.js";
import { updateIntro, updateFontColor } from "../../nav/navSlice";
import { _navs } from "../../nav/navAPI";
import "./Service.css";

export default function Service() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateFontColor(2));
    dispatch(updateIntro(_navs.filter( item => item.to === window.location.pathname)[0].Id));
  }, [dispatch]);

  const listRepresentativeIntroduction = _representativeIntroduction.map( item => {
    return  <li 
              key={`representativeIntroduction-${item["Id"]}`}>
              <b>{item["title"]}</b>{item["description"]} 
            </li>
  });

  const listServices = _services.map( (item, index) => {

    return (
      <React.Fragment>
        <div 
          key={`service-${item["Id"]}`} 
          className="service-item">
          <div>
            <img src={item["src"]} alt=""/>
          </div>
          <div>
            <ul>
              {item["list"].map(item => {
              return (
                <li>{item}</li>
              )})}
            </ul>
          </div>
        </div>
        {
          _services.length - 1 > index ? (<img src="assets/images/service/arrow.png" alt=""/>) : ""
        }
      </React.Fragment>
    )
  });

  return (
    <main className="service-main">
      <h2>인사말</h2>
      <section className="companyIntroduction-section">
        <div>
          <h3>Open the door, Open the future  <b>공간두드림</b></h3>
          <p>공간두드림」은 도시계획 전문회사로 창조적이고 발전적인 도시공간을 고민하고 계획하며, 공공과 민간의 입장을 합리적으로 조율하고, 보다 나은 가치를 지닌 도시공간 창출을 위해 노력하고 있습니다.
          </p>
        </div>
      </section>
      <section 
        className="representativeIntroduction-section"
        style={{
          backgroundImage: `url("assets/images/service/barDesign.png")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "95%"
        }}>
        <div>
          <h3><img src="assets/images/service/iconSquare.png"/>&nbsp; &nbsp;대표소개</h3>
          <ul>
            {listRepresentativeIntroduction ? listRepresentativeIntroduction : ""}
          </ul>
        </div>
      </section>
      <section className="service-section">
        <h2>서비스</h2>
        <p>다양한 프로젝트 경험을 접목하여 협업을 통해 인허가 리스크를 최소하고, 계획 실현을 위해 최선을 다하겠습니다.</p>
        <div className="service-item-container">
          {listServices ? listServices : ""}
        </div>
      </section>
    </main>
  );
}