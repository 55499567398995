export const fetchMolitPnUofLandQnAContents = async (targetUrl) => {
  const url = `/fetchMolitPnUofLandQnAContents`;

  try{
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        targetUrl: targetUrl
      }),
      headers: {
        'Content-type': 'application/json'
      }
    });

    if(response.ok) {
      const jsonResponse = await response.json();
      return jsonResponse.root.AnswerList;
    }

  } catch(error) {
    console.log(error);
  }
}
